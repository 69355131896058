(function($) {
    $.widget('ogn.sloganparallax', {
        options: {
            offset: 20
        },
        _create: function() {
            var $branch = $('.branch', this.element);
            var tween = new TimelineMax ()
                .add([
                    TweenMax.fromTo($branch, 1, {top: '0%'}, {top: '5%' }),
                ]);
            new $.ScrollMagic.Scene({
                triggerElement: this.element,
                triggerHook: 0.7,
                logLevel: 3
            })
            .setClassToggle(this.element, 'visible')
            .addTo(window.scrollMagicController);
            this.branchScene = new $.ScrollMagic.Scene({
                triggerElement: this.element,
                duration: this._duration(),
                triggerHook: 0.7,
                offset: this.options.offset,
                logLevel: 3
            })
            .setTween(tween)
            .addTo(window.scrollMagicController)
        },
        _init: function() {
            this._on(this.window, {
                resize: function() {
                    this.branchScene.duration(this._duration())
                    this.branchScene.refresh();
                }
            });
        },
        _duration: function() {
            return this.element.height() - this.options.offset;
        },
    })
    $('.component-slogan').sloganparallax();
})(jQuery);