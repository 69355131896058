(function($) {
    $.widget('ogn.contactparallax', {
        options: {
            offset: -200,
            triggerHook: 0.5
        },
        _create: function() {
            this.$background = $('<div>').addClass('parallax-background').prependTo(this.element);
            var tween = new TimelineMax ()
                .add([
                    TweenMax.fromTo(this.$background, {scale: 1}, {scale: 1.1 }),
                ]);
            this.cellsScene = new $.ScrollMagic.Scene({
                triggerElement: this.element,
                duration: this._duration(),
                triggerHook: this.options.triggerHook,
                offset: this.options.offset,
                logLevel: 3
            })
            .setTween(tween)
            .addTo(window.scrollMagicController)
        },
        _duration: function() {
            return this.element.height() - (this.options.offset * 2);
        },
    });
    $('.template-home .component-contact').contactparallax();
})(jQuery)