document.addEventListener('DOMContentLoaded', () => {
  const lazyImages = document.querySelectorAll('img.lazy-load');

  const loadImage = img => {
    img.src = img.dataset.src;
    img.removeAttribute('data-src');
    img.classList.remove('lazy-load');
  };

  if ('IntersectionObserver' in window) {
    const observer = new IntersectionObserver((entries, obs) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          loadImage(entry.target);
          obs.unobserve(entry.target);
        }
      });
    });

    lazyImages.forEach(img => {
      observer.observe(img);
    });
  } else {
    lazyImages.forEach(loadImage);
  }
});
