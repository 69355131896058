$(function() {
    if ($('#case-studies-request-form').length) {
        $('#case-studies-preferred-method-of-contact-container')['preferred-method-of-contact']();
        $('#case-studies-sign-up-for-email-options-container')['sign-up-for-email-options']();
        var CaseStudiesRequestForm = function(selector) {
            ContactForm.call(this, selector);
            var hostname = window.location.hostname;
            this.dataUrl = 'https://leads.organogenesis.dev/webform_rest/submit';
            this.dataAPIKey = 'PsAxqib6hr3anKOn3eouHHw7Hi3yFyrK';
            this.emailUrl = 'https://leads.organogenesis.dev/webform_rest/submit';
            this.emailAPIKey = 'DShuQTBjQjJrYeX5DsX83cjX3BzTXQTNB';

            if (/^affinityfresh\.com$/.test(hostname) || /^www\.affinityfresh\.com$/.test(hostname)) {
                this.dataUrl = 'https://leads.organogenesis.com/webform_rest/submit';
                this.emailUrl = 'https://leads.organogenesis.com/webform_rest/submit';
            } else if (/^affinityfresh\.dev$/.test(hostname) || /^www\.affinityfresh\.dev$/.test(hostname)) {
                this.dataUrl = 'https://leads.organogenesis.dev/webform_rest/submit';
                this.emailUrl = 'https://leads.organogenesis.dev/webform_rest/submit';
            } else if (/^sfsk-affinityfresh-ssi\.lndo\.site$/.test(hostname)) {
                this.dataUrl = 'https://sfsk-organogenesis-leads-d10.lndo.site/webform_rest/submit';
                this.emailUrl = 'https://sfsk-organogenesis-leads-d10.lndo.site/webform_rest/submit';
            }

            var fieldsOptions = this.fieldsOptions = [
                { id: '#case-studies-request-first-name-container', name: 'firstName', validators: ['required'] },
                { id: '#case-studies-request-last-name-container', name: 'lastName', validators: ['required'] },
                {
                    id: '#case-studies-request-specialty-container',
                    name: 'specialty',
                    variants: [
                        { value: '', label: '' },
                        { value: 'dermatologist', label: 'Dermatologist' },
                        { value: 'podiatrist', label: 'Podiatrist' },
                        { value: 'plastic-surgeon', label: 'Plastic Surgeon' },
                        { value: 'general-surgeon', label: 'General Surgeon' },
                        { value: 'vascular-surgeon', label: 'Vascular Surgeon' },
                        { value: 'gerontologist', label: 'Gerontologist' },
                        { value: 'primary-care-physician', label: 'Primary Care Physician' },
                        { value: 'internal-medicine', label: 'Internal Medicine' },
                        { value: 'nurse', label: 'Nurse' },
                        { value: 'hospitalist', label: 'Hospitalist' },
                        { value: 'orthopedic-surgeon', label: 'Orthopedic Surgeon' },
                        { value: 'spine-orthopedic-surgeon', label: 'Spine Orthopedic Surgeon' },
                        { value: 'neurosurgeon', label: 'Neurosurgeon' },
                        { value: 'pain physician', label: 'Pain Physician' },
                        { value: 'other', label: 'Other' }
                    ],
                    validators: ['required']
                },
                { id: '#case-studies-request-npi-container', name: 'npi' },
                { id: '#case-studies-request-institution-affiliation-container', name: 'institution' },
                { id: '#case-studies-request-zip-code-container', name: 'zip', validators: ['zip'] },
                {
                    id: '#case-studies-request-reason-for-contact-container',
                    name: 'contactReason',
                    variants: [
                        { value: '', label: '' },
                        { value: 'discussion-with-organogenesis-representative', label: 'Discussion with Organogenesis Representative' },
                        { value: 'literature-request', label: 'Literature request' },
                        { value: 'product-samples', label: 'Product Samples' },
                        { value: 'other', label: 'Other' }
                    ],
                    validators: ['required']
                },
                { id: '#case-studies-preferred-method-of-contact-container', validators: ['required'] },
                { id: '#case-studies-sign-up-for-email-options-container', validators: ['required'] }
            ]
            this.$fields = $('.scenario-container')['scenario-input']();
            this.fields = this.$fields.map(function() {
                var widget = $(this)['scenario-input']().data('ognScenarioInput');
                var options = fieldsOptions.find(function(o) {
                    return o.id === widget.hashID;
                });
                if (options) {
                    widget.update(options);
                }
                return widget;
            }).get();
            this.$userTypeRadio = $('input[name="userType"]', selector);
            this.$userTypeRadio.on('change', this.onChangeUserType.bind(this));
            this.$container.on('hidden.bs.modal', this.onCloseModalWindow.bind(this));
            this.$euEconomicArea = $('.eu-economic-area');
            this.$euEconomicAreaCheckboxes = $('.eu-economic-area input[type="checkbox"]');
            this.$generalAgreementCheckboxes = $('.scenario-agreement:not(.eu-economic-area):not(#eu-economic-area) input[type="checkbox"]');
            this.$euEconomicArea.on('hide', this.onHideEUEconomicArea.bind(this));
            this.$euEconomicAreaToggler = $('#eu-economic-area input[type="checkbox"]');
            this.$euEconomicAreaToggler.on('change', this.onChangeEUEconomicArea.bind(this));
            $('.scenario-agreement input[type="checkbox"]').on('change', this.onChangeAgreementValue.bind(this));
        };
        CaseStudiesRequestForm.prototype = Object.create(ContactForm.prototype);
        CaseStudiesRequestForm.prototype.constructor = CaseStudiesRequestForm;
        CaseStudiesRequestForm.prototype.validate = function() {
            this.errors = false;
            this.fields.forEach(this.onFieldValidate.bind(this));
        };
        CaseStudiesRequestForm.prototype.onFieldValidate = function($field) {
            // var $field = $(element);
            var isInvalid = !$field.validate();
            if (isInvalid) {
                this.errors = true;
            }
        };
        CaseStudiesRequestForm.prototype.serialize = function() {
            var formData = this.fields.reduce(this.onFieldSerialize.bind(this), {});
            var origin = window.location.href;
            formData.origin = origin;
            formData.webform_id = 'affinityfresh_case_studies';
            var userTypes = {
                'hcp': 'US HCP',
                'not-hcp': 'Not US HCP'
            };
            formData['userType'] = userTypes[this.iAmValue];
            formData['areaOfInterest'] = "Case Studies";
            // Extract specialty
            var specialties = this.fieldsOptions.find(function(element) {
                return element.name === 'specialty'
            });
            if (formData['specialty'] && specialties) {
                var specialty = specialties.variants.find(function(element) {
                    return element.value === formData['specialty'];
                });
                formData['specialty'] = specialty ? specialty.label : formData['specialty'];
            }
            // Extract Reason for contact
            var contactReasons = this.fieldsOptions.find(function(element) {
                return element.name === 'contactReason';
            });
            if (formData['contactReason'] && contactReasons) {
                var contactReason = contactReasons.variants.find(function(element) {
                    return element.value === formData['contactReason'];
                });
                formData['contactReason'] = contactReason ? contactReason.label : formData['contactReason'];
            }

            var agreements = this.$generalAgreementCheckboxes.get().reduce(function(results, checkbox) {
                var $checkbox = $(checkbox);
                results[$checkbox.attr('name')] = $checkbox.is(':checked');
                return results;
            }, {});
            agreements[this.$euEconomicAreaToggler.attr('name')] = this.$euEconomicAreaToggler.is(':checked');
            if (this.$euEconomicAreaToggler.is(':checked')) {
                agreements = this.$euEconomicAreaCheckboxes.get().reduce(function(results, checkbox) {
                    var $checkbox = $(checkbox);
                    results[$checkbox.attr('name')] = $checkbox.is(':checked');
                    return results;
                }, agreements);
            }
            formData = Object.assign(formData, agreements);
            return formData;
        };
        CaseStudiesRequestForm.prototype.onFieldSerialize = function(formData, $field) {
            var data = $field.serialize();
            return Object.assign(formData, data);
        };
        CaseStudiesRequestForm.prototype.onChangeAgreementValue = function(event) {
            if (event.target !== event.currentTarget) {
                return;
            }
            var $button = $('#case-studies-request-form-submit');
            var isButtonDisabled = false;
            isButtonDisabled = this.$generalAgreementCheckboxes.map(function() {
                return $(this).is(':checked');
            }).get().some(function(isChecked) {
                return !isChecked;
            });
            if (isButtonDisabled) {
                $button.prop('disabled', true);
                return;
            }
            if (this.$euEconomicAreaToggler.is(':checked')) {
                isButtonDisabled = this.$euEconomicAreaCheckboxes.map(function() {
                    return $(this).is(':checked');
                }).get().some(function(isChecked) {
                    return !isChecked;
                });
            }
            if (isButtonDisabled) {
                $button.prop('disabled', true);
                return;
            }
            $button.prop('disabled', false);
        }
        CaseStudiesRequestForm.prototype.onHideEUEconomicArea = function(event) {
            if (event.target !== event.currentTarget) {
                return;
            }
            $('input', event.target).prop('checked', false);
        }
        CaseStudiesRequestForm.prototype.onChangeEUEconomicArea = function(event) {
            if (event.target !== event.currentTarget) {
                return;
            }
            if ($(event.target).is(':checked')) {
                this.$euEconomicArea.show();
            } else {
                this.$euEconomicArea.hide();
            }
        };
        CaseStudiesRequestForm.prototype.onChangeUserType = function(event) {
            event.preventDefault();
            var $current = $(event.currentTarget);
            var $form = $('.form-holder', this.selector);
            if ($current.is(':checked')) {
                this.iAmValue = $current.val();
                $form.addClass('visible');
            } else {
                $form.removeClass('visible');
            }
        };
        CaseStudiesRequestForm.prototype.clearForm = function() {
            var $userTypeRadio = $('input[name="userType"]', this.selector);
            $userTypeRadio.removeAttr('checked').trigger('change');
            var $form = $('form', this.selector);
            $form.trigger('reset');
            $form.removeClass('has-errors');
            this.fields.forEach(function($field) {
                $field._clearErrors();
            })
            $('#case-studies-request-form-submit').prop('disabled', true);
        };
        CaseStudiesRequestForm.prototype.onCloseModalWindow = function() {
            this.clearForm();
        };
        CaseStudiesRequestForm.prototype.request = function($form, success, error) {
            var self = this;
            this.save($form).then(function onSuccess() {
                success();
                self.clearForm();
            }, error);
        };
        CaseStudiesRequestForm.prototype.containerSuccess = function() {
            var container = $(this);
            container.addClass('success');
            
            setTimeout(function() {
                container.removeClass('success');
            }, 10000)
        }
        new CaseStudiesRequestForm('#case-studies-request-form');
    }
});
