(function($) {
    $.widget('ogn.introparallax', {
        _create: function() {
            var configs = {
                triggerElement: this.element,
                triggerHook: 0.5,
                logLevel: 3,
                offset: -150
            }
            var $element = $('.holder', this.element);
            new $.ScrollMagic.Scene(configs).setClassToggle($element, 'visible').addTo(window.scrollMagicController);
        }
    })
    $('.home-intro').introparallax();
})(jQuery);