(function($) {
    $.widget('ogn.anchorlink', {
        _create: function() {
            this.$target = $(this.element.attr('href'));
            this.hasTarget = this.$target.length === 1;
        },
        _init: function() {
            if (this.hasTarget) {
                this.element.on('click', this._onClick.bind(this));
            }
        },
        _onClick: function(event) {
            event.preventDefault();
            if (this.hasTarget) {
                this.scrollTo = this.$target.offset().top - offsetNavigationHeight;
                $('html,body').animate({ scrollTop: this.scrollTo }, 'slow');
            }
        }
    });
    $(document).ready(function() {
        $('.anchor').anchorlink();
    });
})(jQuery);