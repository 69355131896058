(function($){
    $.widget('ogn.allofreshchart', {
        _create: function() {
            this.$img = $('.step-item-image img', this.element);
            this.$open = $('.step-item-image a', this.element);
            this.$description = $('.step-item-description', this.element);
            this.$close = $('.step-item-description .btn-close', this.element);
        },
        _init: function() {
            this._on(this.$img, {
                click: function(e) {
                    e.preventDefault();
                    this.$description.toggleClass('visible');
                    return false;
                }
            })
            this._on(this.$open, {
                click: function(e) {
                    e.preventDefault();
                    this.$description.toggleClass('visible');
                    return false;
                }
            })
            this._on(this.$close, {
                click: function(e) {
                    e.preventDefault();
                    this.$description.toggleClass('visible');
                    return false;
                }
            })
        }
    })
    $('.component-chart-allofresh .step-item').allofreshchart();
})(jQuery);