$(function() {
    if ($('#form-email-signup').length) {
        var EmailSignupForm = function(selector) {
            ContactForm.call(this, selector);
            var hostname = window.location.hostname;
            this.dataUrl = 'https://leads.organogenesis.dev/webform_rest/submit';
            this.dataAPIKey = 'PsAxqib6hr3anKOn3eouHHw7Hi3yFyrK';

            if (/^affinityfresh\.com$/.test(hostname) || /^www\.affinityfresh\.com$/.test(hostname)) {
                this.dataUrl = 'https://leads.organogenesis.com/webform_rest/submit';
            } else if (/^affinityfresh\.dev$/.test(hostname) || /^www\.affinityfresh\.dev$/.test(hostname)) {
                this.dataUrl = 'https://leads.organogenesis.dev/webform_rest/submit';
            } else if (/^sfsk-affinityfresh-ssi\.lndo\.site$/.test(hostname)) {
                this.dataUrl = 'https://sfsk-organogenesis-leads-d10.lndo.site/webform_rest/submit';
            }

            this.isOtherSpecialtyRequired = false;
            var $otherContainer = this.$fields.filter('div#other-specialty-container');
            if ($otherContainer.length) {
                this.$otherContainer = $otherContainer;
                $('select[name="specialty"]', selector).on('change', this.onChangeSpecialty.bind(this));
            }
            $('input[name="user-type"]', selector).on('change', this.onChangeUserType.bind(this));
            this.$container.on('hidden.bs.modal', this.onCloseModalWindow.bind(this));
        };
        EmailSignupForm.prototype = Object.create(ContactForm.prototype);
        EmailSignupForm.prototype.constructor = EmailSignupForm;
        EmailSignupForm.prototype.validate = function() {
            ContactForm.prototype.validate.call(this);
            if (this.isOtherSpecialtyRequired && this.$otherContainer) {
                var $input = $('input', this.$otherContainer);
                var value = $input.eq(0).val();
                var isValid = isValidString(value);
                var message = 'Required field';
                if (!isValid) {
                    this.errors = true;
                    this.$otherContainer.addClass('error');
                    var $specialtyContainer = this.$fields.filter('#signup-specialty-container');
                    var $error = $specialtyContainer.find('label span');
                    if ($specialtyContainer.length && $error) {
                        $error.html(message);
                        $specialtyContainer.addClass('error');
                    }
                }
            }
        };
        EmailSignupForm.prototype.onCloseModalWindow = function() {
            this.$form.removeClass('has-errors');
            this.$fields.trigger('clear');
            this.$container.removeClass('success');
            if (this.$otherContainer) {
                this.$otherContainer.hide();
            }
        };
        EmailSignupForm.prototype.onChangeUserType = function(event) {
            event.preventDefault()
            var userType = $(event.currentTarget).val();
            var form = $('#form-email-signup form > div:not(.form-group)');
            if (userType === 'patient') {
                window.open('https://organogenesis.com/contact-us/?reason=customer-support', '_blank');
                form.removeClass('visible');
                this.$container.modal('hide');
            } else if (userType === 'professional') {
                form.addClass('visible');
            }
        };
        EmailSignupForm.prototype.onChangeSpecialty = function(event) {
            var $otherContainer = this.$fields.filter('div#other-specialty-container');
            if ($otherContainer.length) {
                var specialty = $(event.currentTarget).val();
                if (specialty === 'other') {
                    this.isOtherSpecialtyRequired = true;
                    $otherContainer.show();
                } else {
                    this.isOtherSpecialtyRequired = false;
                    $otherContainer.hide();
                }

            }
        };
        EmailSignupForm.prototype.serialize = function($form) {
            var formData = $form.serializeArray().reduce(function(data, field) {
                data[field.name] = field.value;
                return data;
            }, {});
            if(formData.specialty === 'other') {
                formData.specialty = formData['other-specialty'];
            } else {
                formData.specialty = CONTACT_FORM_SPECIALTIES[formData.specialty];
            }
            return {
              'user-type': formData['user-type'],
              'specialty': formData['specialty'],
              'name': formData['name'],
              'firstName': formData['firstName'],
              'lastName': formData['lastName'],
              'email': formData['email'],
              'webform_id': 'affinityfresh_email_signup'
            };
        };
        EmailSignupForm.prototype.request = function($form, success, error) {
            this.save($form).then(success, error);
        };
        EmailSignupForm.prototype.containerSuccess = function() {
            dataLayer.push({'event':'SIGN UP FOR MORE INFORMATION Form Submission', 'specialty_value':CONTACT_FORM_SPECIALTIES[$('select[name="specialty"]').val()]});
            var container = $(this);
            container.addClass('success');
            setTimeout(function() {
                container.removeClass('success');
            }, 10000)
        }
        new EmailSignupForm('#form-email-signup');
    }
});
