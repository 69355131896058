var CONTACT_FORM_SPECIALTIES = {
    'dermatologist': 'Dermatologist',
    'podiatrist': 'Podiatrist',
    'plastic-surgeon': 'Plastic Surgeon',
    'general-surgeon': 'General Surgeon',
    'vascular-surgeon': 'Vascular Surgeon',
    'gerontologist': 'Gerontologist',
    'primary-care-physician': 'Primary Care Physician',
    'internal-medicine': 'Internal Medicine',
    'nurse': 'Nurse',
    'hospitalist': 'Hospitalist',
    'other': 'Other'
};

var CONTACT_FORM_REASON = {
    'local-specialist': 'Contact from a local Organogenesis Tissue Generation Specialist',
    'reimbursement': 'Reimbursement / Insurance Inquiry',
    'product-information': 'Product Information',
    'product-order': 'Product Order or Delivery Support',
    'medical-consultation': 'Medical or Technical Consultation',
    'patient-assistance': 'Patient Assistance Information',
    'general-question': 'General Question',
    'website-feedback': 'Website Feedback'
}
var isValidString = function(value) {
    return !!value && value.length > 0;
};
var isValidEmail = function(value) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
};
var isValidPhone = function(value) {
    var re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    return !!value && value.length > 0 && re.test(value);
};
var isValidZip = function(value) {
    return !!value && value.length >= 5;
};
var offsetNavigationHeight = 80;
var Cookie = {
    get: function (name) {
        var matches = document.cookie.match(new RegExp(
          "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? matches[1] : undefined;
    },
    set: function(name, value, options) {
        var updatedCookie = name + "=" + value;
        if (options && options.expires instanceof Date) {
            updatedCookie += "; expires=" + options.expires.toUTCString();
        }
        if (options && options.path && options.path.length) {
            updatedCookie += "; path=" + options.path;
        }
        document.cookie = updatedCookie;
    }
};

var ContactForm = function(selector) {
    this.errors = false;
    this.selector = selector;
    this.$container = this.elements.container(selector);
    this.$container.on('success', this.containerSuccess);
    this.$form = this.elements.form(this.$container);
    this.$form.submit(this.onFormSubmit.bind(this));
    this.$fields = this.elements.fields(this.$form, this);
    this.$fields.on('clear:errors', this.onClearFieldError);
    this.$submit = this.elements.submit(this.$form);
};
ContactForm.prototype.onFormSubmit = function(e) {
    e.preventDefault();
    this.validate();
    this.$submit.trigger('disable');
    if (this.errors) {
        this.onValidationError();
    } else {
        this.request(
            this.$form,
            this.onFormSuccess.bind(this),
            this.onFormError.bind(this));
    }
    return false;
};
ContactForm.prototype.onFormSuccess = function() {
    this.$submit.trigger('enable');
    this.$container.trigger('success');
    this.$fields.trigger('clear');
};
ContactForm.prototype.onFormError = function(res) {
    if (!!res && !!res.responseJSON)
        console.log(res.responseJSON.message);
    this.$submit.trigger('error');
};
ContactForm.prototype.onValidationError = function() {
    this.$submit.trigger('enable');
    this.$form.addClass('has-errors');
};
ContactForm.prototype.validate = function() {
    this.errors = false;
    this.$form.removeClass('has-errors');
    this.$fields.trigger('valid');
};
ContactForm.prototype.containerSuccess = function() {
    $(this).addClass('success');
    setTimeout(function() {
        $container.removeClass('success');
    }, 10000)
};
ContactForm.prototype.elements = {
    container: function(selector) {
        var $container = $(selector).eq(0);
        if (!$container) throw new Error('Selector ' + selector + ' not found.');
        return $container;
    },
    form: function($container) {
        var $form = $('form', $container).eq(0);
        if (!$form) throw new Error('Form not found.');
        return $form;
    },
    fields: function($form, form) {
        var $fields = $('.form-group', $form);
        $fields.on('valid', function() {
            var $container = $(this);
            $container.trigger('clear:errors');
            var $error = $('.error-holder', $container);
            var $icon = $('.error-icon', $container);
            var $input = $('input', $container);
            if (!$input.length) $input = $('select', $container);
            if (!$input.length) $input = $('textarea', $container);
            if (!$input.length) return;
            var value = $input.eq(0).val();
            var validators = $input.attr('data-validators');
            var isValid = true,
                message;
            if (validators && validators.indexOf('email') > -1) {
                isValid = isValidEmail(value);
                message = 'Invalid email';
            }
            if (validators && validators.indexOf('required') > -1) {
                isValid = isValidString(value);
                message = 'Required field';
            }
            if (!isValid) {
                $error.html(message);
                $container.addClass('error');
                form.errors = true;
                if (!$icon.length) {
                    $error.before('<i class="icon icon-info-circle font-weight-bold error-icon"></i>');
                }
            }
        });
        $fields.on('clear', function() {
            $(this).trigger('clear:errors')
            var $input = $('input', this);
            if (!$input.length) $input = $('textarea', this);
            if (!$input.length) {
                $input = $('select', this);
                $input.parent().find('.select').trigger('set:default');
            }
            if ($input.attr('type') == 'radio' || $input.attr('type') == 'checkbox') {
                $input.prop('checked', false);
                if ($input.attr('type') === 'radio') {
                    $('> div:not(.form-group)', $form).removeClass('visible');
                }
            } else {
                $input.val('');
            }
        });
        $fields.on('hide', function() {
            $(this).trigger('clear');
        });
        return $fields;
    },
    submit: function($form) {
        var $submit = $('.form-submit', $form);
        var $button = $('button', $submit);
        $submit.on('disable', function() {
            $submit.addClass('disabled');
            $button.attr('disabled', true);
        });
        $submit.on('enable', function() {
            $submit.removeClass('disabled');
            $button.attr('disabled', false);
        });
        $submit.on('error', function() {
            $submit.addClass('error');
            $submit.removeClass('disabled');
            $button.attr('disabled', false);
            setTimeout(function() {
                $submit.removeClass('error');
            }, 10000);
        })
        return $submit;
    }
}
ContactForm.prototype.onClearFieldError = function() {
    var $container = $(this);
    var $error = $('.error-holder', $container);
    if (!$error.length) {
        $error = $('<span>').addClass('error-holder');
        $container.append($error);
    } else {
        $error.html('');
    }
    if ($container.hasClass('error')) {
        $container.removeClass('error');
    }
};
ContactForm.prototype.save = function($form) {
    var formData = this.serialize($form);
    var key = this.dataAPIKey;
    return $.ajax({
        method: 'POST',
        url: this.dataUrl,
        data: JSON.stringify(formData),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + key);
        },
    });
}
ContactForm.prototype.send = function($form) {
    var formData = this.serialize($form);
    delete formData['user-type']
    
    var key = this.emailAPIKey;
    return $.ajax({
        method: 'POST',
        url: this.emailUrl,
        data: JSON.stringify(formData),
        contentType: 'application/json',
        dataType: 'json',
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + key);
        },
    });
}
ContactForm.prototype.serialize = function($form) {
    var formData = $form.serializeArray().reduce(function(data, field) {
        data[field.name] = field.value;
        return data;
    }, {});
    return formData;
}
ContactForm.prototype.request = function($form, success, error) {
    var self = this;
    this.save($form)
        .then(function() {
            self.send($form);
            success();
        }, error);
}
if (!window.scrollMagicController) {
    window.scrollMagicController = new ScrollMagic.Controller({
        addIndicators: false
    });
}
$(function() {
    offsetNavigationHeight = 80;
    $.each(['hide', 'show'], function (i, ev) {
        var el = $.fn[ev];
        $.fn[ev] = function () {
          this.trigger(ev);
          return el.apply(this, arguments);
        };
    });
});

$.widget('ogn.preferred-method-of-contact', {
    _create: function() {
        this.$phone = $('#preferred-method-phone-container', this.element);
        this.$email = $('#preferred-method-email-container', this.element);
    },
    _init: function() {
        $('input[name="preferred"]', this.element).on('change', this._change.bind(this));
    },
    _change: function(event) {
        var value = $(event.currentTarget).val();
        if (value === 'email') {
            this.$phone.hide();
            this.$email.show();
        } else {
            this.$phone.show();
            this.$email.hide();
        }
    }
});
$.widget('ogn.sign-up-for-email-options', {
    _create: function() {
        var related = this.element.data('related');
        this.$related = $(related);
        this.$email = $('#sign-up-email-container', this.element);
        this.relatedValue = 'email';
        this.isChecked = false;
        this.isHidden = true;
    },
    _init: function() {
        $('input[name="sign-up-for-email-options"]', this.element).on('change', this._onChangeCheckbox.bind(this));
        this.$related.on('change', this._onChangeRelated.bind(this));
        this.element.on('show', this._onShow.bind(this));
        this.element.on('hide', this._onHide.bind(this));
    },
    _onShow: function() {
        this.isHidden = false;
    },
    _onHide: function() {
        this.isHidden = true;
    },
    _onChangeCheckbox: function(event) {
        this.isChecked = $(event.currentTarget).prop('checked');
        this._toggleInput();
    },
    _onChangeRelated: function(event) {
        this.relatedValue = $(event.currentTarget).val();
        this._toggleInput();
    },
    _toggleInput: function() {
        if (this.isChecked && this.relatedValue !== 'email') {
            this.$email.show();
        } else {
            this.$email.hide();
        }
    }
});
$.widget('ogn.scenario-input', {
    _create: function() {
        this.id = this.element.attr('id');
        this.hashID = '#' + this.id;
        this.validators = [];

        this.isFormGroup = this.element.hasClass('form-group');
        this.isPreferredMethodOfContact = this.element.hasClass('preferred-method-of-contact');
        this.isSignupForEmailsOption = this.element.hasClass('sign-up-for-email-options');
        if (this.isFormGroup) {
            this.$input = $('input, select', this.element);
            this.$errorHolder = $('<span>').addClass('error-holder').appendTo(this.element);
        }
        this.preferredMethod = 'email';
        if (this.isPreferredMethodOfContact) {
            this.$phoneHolder = $('#preferred-method-phone-container', this.element);
            this.$emailHolder = $('#preferred-method-email-container', this.element);
            this.$phoneInput = $('input[name="preferred-phone"]', this.element);
            this.$emailInput = $('input[name="preferred-email"]', this.element);
            this.$phoneErrorHolder = $('<span>').addClass('error-holder').appendTo(this.$phoneHolder);
            this.$emailErrorHolder = $('<span>').addClass('error-holder').appendTo(this.$emailHolder);
        }
        if (this.isSignupForEmailsOption) {
            this.isSignupChecked = false;
            this.$emailHolder = $('#sign-up-email-container', this.element);
            this.$emailInput = $('input[name="signup-email"]', this.element);
            this.$emailErrorHolder = $('<span>').addClass('error-holder').appendTo(this.$emailHolder);
        }
    },
    _init: function() {
        this.element.on('show', this._onShow.bind(this));
        this.element.on('hide', this._onHide.bind(this));
        if (this.isPreferredMethodOfContact || this.isSignupForEmailsOption) {
            $('input[name="preferred"]').on('change', this._onChangePreferredRadio.bind(this));
            $('input[name="sign-up-for-email-options"]', this.element).on('change', this._onChangeSignupCheckbox.bind(this));
        }
    },
    _onChangeSignupCheckbox: function(event) {
        if (this.isSignupForEmailsOption) {
            this.isSignupChecked = $(event.currentTarget).prop('checked');
        }
    },
    _onChangePreferredRadio: function(event) {
        if (this.isPreferredMethodOfContact || this.isSignupForEmailsOption) {
            this.preferredMethod = $(event.currentTarget).val();
        }
    },
    _onShow: function(event) {
        event.preventDefault();
        if (this.element.get(0) !== event.target) return false;
        this._clearErrors();
    },
    _onHide: function(event) {
        event.preventDefault();
        if (this.element.get(0) !== event.target) return false;

        if (this.isFormGroup) {
            this.$input.val('');
            var tagName = this.$input.prop('tagName');
            if (tagName === 'SELECT') {
                $('.component-select', this.element).trigger('set:default');
            }
        }
        if (this.isPreferredMethodOfContact) {
            $('input[name="preferred"][value="email"]').prop('checked', true);
            this.$phoneHolder.hide();
            this.$emailHolder.show();
            this.$phoneInput.val('');
            this.$emailInput.val('');
        }
        if (this.isSignupForEmailsOption) {
            $('input[name="sign-up-for-email-options"]', this.element).prop('checked', false);
            this.$emailHolder.hide();
            this.$emailInput.val('');
        }
        return false;
    },
    _clearErrors: function() {
        $('.error-icon', this.element).remove();
        if (this.$errorHolder) {
            this.$errorHolder.empty();
        }
        if (this.$phoneErrorHolder) {
            this.$phoneErrorHolder.empty();
        }
        if (this.$emailErrorHolder) {
            this.$emailErrorHolder.empty();
        }
        if (this.$phoneHolder) {
            this.$phoneHolder.removeClass('error');
        }
        if (this.$emailHolder) {
            this.$emailHolder.removeClass('error');
        }
        this.element.removeClass('error');
    },
    update: function(options) {
        if (options.validators) {
            this.validators = options.validators;
        }
        if (options.name) {
            this.serializeName = options.name;
        }
        if (this.isFormGroup) {
            var tagName = this.$input.prop('tagName');
            if (tagName === 'SELECT' && Array.isArray(options.variants)) {
                var options = options.variants.map(function(item) {
                    return $('<option>').attr('value', item.value).html(item.label);
                });
                this.$input.empty().append(options).dropdownselect('rebuild');
            }
        }
    },
    validate: function() {
        var isValid = true, message = '';
        this._clearErrors();
        if (!this.validators.length) {
            return isValid;
        }
        if (this.isFormGroup) {
            var value = this.$input.val();
            if (this.validators.indexOf('required') > -1) {
                isValid = isValidString(value);
                message = 'Required field';
            }
            if (this.validators.indexOf('email') > -1) {
                isValid = isValidEmail(value);
                message = 'Email is invalid';
            }
            if (this.validators.indexOf('phone') > -1) {
                isValid = isValidPhone(value);
                message = 'Phone is invalid';
            }
            if (this.validators.indexOf('zip') > -1) {
                isValid = isValidZip(value);
                message = 'ZIP/Postal code is invalid';
            }
            if (!isValid) {
                this.element.addClass('error');
                this.$errorHolder.html(message)
                if (!this.element.has('i').length) {
                    this.$errorHolder.before('<i class="icon icon-info-circle font-weight-bold error-icon"></i>');
                }
                return isValid;
            }
            
        }
        if (this.isPreferredMethodOfContact) {
            var phoneValue = this.$phoneInput.val();
            var emailValue = this.$emailInput.val();
            if (this.validators.indexOf('required') > -1 && this.preferredMethod === 'phone') {
                isValid = isValidPhone(phoneValue);
                message = 'Phone is invalid';
                if (!isValid) {
                    this.$phoneHolder.addClass('error');
                    this.$phoneErrorHolder.html(message)
                    if (!this.$phoneHolder.has('i').length) {
                        this.$phoneErrorHolder.before('<i class="icon icon-info-circle font-weight-bold error-icon"></i>');
                    }
                    return isValid;
                }
            }
            if (this.validators.indexOf('required') > -1 && this.preferredMethod === 'email') {
                isValid = isValidEmail(emailValue);
                message = 'Email is invalid';
                if (!isValid) {
                    this.$emailHolder.addClass('error');
                    this.$emailErrorHolder.html(message)
                    if (!this.$emailHolder.has('i').length) {
                        this.$emailErrorHolder.before('<i class="icon icon-info-circle font-weight-bold error-icon"></i>');
                    }
                    return isValid;
                }
            }
        }
        if (this.isSignupForEmailsOption) {
            var emailValue = this.$emailInput.val();
            if (this.validators.indexOf('required') > -1 && this.preferredMethod !== 'email' && this.isSignupChecked) {
                isValid = isValidEmail(emailValue);
                message = 'Email is invalid';
                if (!isValid) {
                    this.$emailHolder.addClass('error');
                    this.$emailErrorHolder.html(message)
                    if (!this.$emailHolder.has('i').length) {
                        this.$emailErrorHolder.before('<i class="icon icon-info-circle font-weight-bold error-icon"></i>');
                    }
                    return isValid;
                }
            }
        }
        return isValid;
    },
    serialize: function() {
        var response = {};
        if (this.isFormGroup) {
            var value = this.$input.val();
            response[this.serializeName] = value;
            return response;
        }
        if (this.isPreferredMethodOfContact) {
            var phoneValue = this.$phoneInput.val();
            var emailValue = this.$emailInput.val();
            if (this.preferredMethod === 'phone') {
                return { 'preferredMethod': 'phone', phone: phoneValue };
            }
            if (this.preferredMethod === 'email') {
                return { 'preferredMethod': 'email', email: emailValue };
            }
        }
        if (this.isSignupForEmailsOption) {
            if (!this.isSignupChecked) {
                return { 'signupForEmails': false };
            }
            var emailValue = this.$emailInput.val();
            if (this.preferredMethod !== 'email' && this.isSignupChecked) {
                return { 'signupForEmails': true, email: emailValue };
            } else if (this.preferredMethod === 'email' && this.isSignupChecked) {
                return { 'signupForEmails': true };
            }
        }
        return response;
    },
    show: function() {
        this.element.show();
    },
});
