(function($) {
    $.widget( "ogn.cellparallax", {
        options: {
            offset: -200,
            triggerHook: 0.5
        },
        _create: function() {
            this.$container = $('.container', this.element);
            var visabilitySceneOptions = {
                triggerElement: this.element,
                triggerHook: this.options.triggerHook,
                logLevel: 3
            };
            var cellsSceneOptions = {
                triggerElement: this.element,
                duration: this._duration(),
                triggerHook: this.options.triggerHook,
                offset: this.options.offset,
                logLevel: 3
            };
            this.visabilityScene = new $.ScrollMagic.Scene(visabilitySceneOptions)
                .setClassToggle(this.element, 'visible')
                .addTo(window.scrollMagicController);
            this.cellsScene = new $.ScrollMagic.Scene(cellsSceneOptions)
                .addTo(window.scrollMagicController)
            this._createCells();
        },

        _init: function() {
            this._on(this.window, {
                resize: function() {
                    this.cellsScene.duration(this._duration())
                    this.cellsScene.refresh();
                }
            });
        },
        _duration: function() {
            return this.element.height() - (this.options.offset * 2);
        },
        _createCells: function() {
            var $cellsLayer1 = $('<div>').addClass('cells-layer')
            var $cellsLayer2 = $('<div>').addClass('cells-layer')
            var $cellsLayer3 = $('<div>').addClass('cells-layer')
            var $cell1 = $('<div>')
                .addClass('cell-1 cell')
                .css('animation-delay', this._randomizeDelay)
                .css('animation-duration', this._randomizeDuration)
                .appendTo($cellsLayer1);
            
            var $cell2 = $('<div>')
                .addClass('cell-2 cell')
                .css('animation-delay', this._randomizeDelay)
                .css('animation-duration', this._randomizeDuration)
                .appendTo($cellsLayer2);
            var $cell3 = $('<div>')
                .addClass('cell-3 cell')
                .css('animation-delay', this._randomizeDelay)
                .css('animation-duration', this._randomizeDuration)
                .appendTo($cellsLayer2);
            var $cell4 = $('<div>')
                .addClass('cell-4 cell')
                .css('animation-delay', this._randomizeDelay)
                .css('animation-duration', this._randomizeDuration)
                .appendTo($cellsLayer3);
            var $cell5 = $('<div>')
                .addClass('cell-5 cell')
                .css('animation-delay', this._randomizeDelay)
                .css('animation-duration', this._randomizeDuration)
                .appendTo($cellsLayer3);
            var $cell6 = $('<div>')
                .addClass('cell-6 cell')
                .css('animation-delay', this._randomizeDelay)
                .css('animation-duration', this._randomizeDuration)
                .appendTo($cellsLayer3);
            var $cells = $('<div>').addClass('cells');
            this.$container.append([
                $cells, $cellsLayer3, $cellsLayer2, $cellsLayer1
            ]);
            var tween = new TimelineMax ()
                .add([
                    TweenMax.fromTo($cellsLayer1, {translateY: '-10%'}, {translateY: '10%', ease: Linear.easeNone }),
                    TweenMax.fromTo($cellsLayer2, {translateY: '-8%'}, {translateY: '8%', ease: Linear.easeNone }),
                    TweenMax.fromTo($cellsLayer3, {translateY: '-4%'}, {translateY: '4%', ease: Linear.easeNone }),
                    TweenMax.fromTo($cells, {translateY: '-2%'}, {translateY: '2%', ease: Linear.easeNone }),
                ]);
            this.cellsScene.setTween(tween);
        },
        _randomizeDelay: function() {
            return Math.random().toFixed(1) + 's';
        },
        _randomizeDuration: function() {
            return 10 + Math.floor(Math.random() * 10) + 's'
        }

    });
    $('.component-cells').cellparallax();
})(jQuery);